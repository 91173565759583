import * as React from 'react';
import { RecoilRoot, atom } from 'recoil';

type RecoilStateProviderProps = {
  children: React.ReactNode;
};

export const DEFAULT_DATA_PACKAGE_ID = '8c84ceca9934cc9e0a351758';

export const currentDataPackageIdState = atom({
  key: 'currentDataPackageId',
  default: DEFAULT_DATA_PACKAGE_ID as string,
});

export const currentBluetoothNetIdState = atom({
  key: 'currentBluetoothNetId',
  default: null as string,
});

export const currentWiFiNetIdState = atom({
  key: 'currentWiFiNetId',
  default: null as string,
});

export const currentCellIdState = atom({
  key: 'currentCellId',
  default: null as string,
});

export const currentPostIdState = atom({
  key: 'currentPostId',
  default: null as any,
});

export const RecoilStateProvider = ({ children }: RecoilStateProviderProps) => {
  return <RecoilRoot>{children}</RecoilRoot>;
};
