import { useState } from 'react';
import * as z from 'zod';

import { useMountEffect } from '@react-hookz/web';

import { AnchorButton, Button } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import { useAuth } from '@/lib/auth';
import storage from '@/utils/storage';

const cacSchema = z.object({
  values: z.array(z.string()).min(2, 'Required'),
});

const userPassSchema = z.object({
  email: z.string().min(1, 'Required'),
  password: z.string().min(1, 'Required'),
});

const mfaSchema = z.object({
  mfaCode: z.string().min(6, 'Required'),
});

type LoginValues = {
  email?: string;
  password?: string;
  mfaCode?: string;
};

type LoginFormProps = {
  onSuccess: () => void;
};

export const LoginForm = ({ onSuccess }: LoginFormProps) => {
  const { login, isLoggingIn } = useAuth();

  const [userPass, setUserPass] = useState(false);

  const [isCheckingCac, setIsCheckingCac] = useState(true);

  const [mfaUser, setMfaUser] = useState("");
  const [mfaCheck, setMfaCheck] = useState(false);

  const [cacError, setCacError] = useState("");

  useMountEffect(async () => {
    if (window.location.search.length === 0) {
      setIsCheckingCac(false);

      let email = storage.getMfaUser();
      if (email) {
        setMfaUser(email);
        setMfaCheck(true);
        storage.clearMfaUser();
      }

      return;
    }

    if (window.location.search.indexOf("valid=false") > -1) {
      setIsCheckingCac(false);
      setCacError('Invalid client certificate.');
      return;
    }

    const params = window.location.search.substring(1).split("&");
    if (params.length !== 2) {
      setIsCheckingCac(false);
      return;
    }

    let user = await login({ values: [params[0].split("=")[1], params[1].split("=")[1]] })
      .catch(err => {
        setCacError(err.message);
        setIsCheckingCac(false);
        return;
      });
    if (user) {
      onSuccess();
    } else {
      setCacError('Failed to authenticate user.');
    }

    setIsCheckingCac(false);
  });

  return (
    <div>
      <Form<LoginValues, typeof cacSchema | typeof userPassSchema | typeof mfaSchema>
        onSubmit={async (values) => {
          if (userPass) {
            let user = await login(values);
            if (user.email) {
              window.location.replace((window.location.origin + '/login') as unknown as string);
              return;
            }
          } else if (mfaCheck) {
            let user = await login({ email: mfaUser, mfaCode: values.mfaCode });
            if (user) {
              onSuccess();
            }
          }
        }}
        schema={userPass ? userPassSchema : (mfaCheck ? mfaSchema : cacSchema)}
      >
        {({ register, formState }) => (
          <>
          {!userPass && !mfaCheck &&
          <>
            {cacError.length > 0 &&
              <span style={{ color: "red" }}>Invalid client certificate.</span>
            }
            <AnchorButton
              isLoading={isLoggingIn || isCheckingCac}
              href={`/cac-login`}
              className={`w-full ${isCheckingCac ? "opacity-70 cursor-not-allowed pointer-events-none" : ""}`}
            >
              CAC Login
            </AnchorButton>
            <Button
              className={`w-full ${isCheckingCac ? "opacity-70 cursor-not-allowed pointer-events-none" : ""}`}
              onClick={() => { setUserPass(true); }}
            >
              Email / Password Login
            </Button>
          </>
          }
          {userPass &&
          <>
            <InputField
              autoFocus={true}
              type="text"
              placeholderText="Email"
              error={formState.errors['email']}
              registration={register('email')}
            />
            <InputField
              type="password"
              placeholderText="Password"
              error={formState.errors['password']}
              registration={register('password')}
            />
            <div>
              <Button
                style={{ display: "inline-block" }}
                variant="text"
                className="w-5/12"
                onClick={() => { setUserPass(false); }}
              >
                Cancel
              </Button>
              <Button
                style={{ float: "right" }}
                isLoading={isLoggingIn}
                type="submit"
                className="w-5/12"
              >
                Log in
              </Button>
            </div>
          </>
          }
          {mfaCheck &&
          <>
            <InputField
              autoFocus={true}
              type="text"
              placeholderText="Multi-Factor Authentication Code (Check Email)"
              error={formState.errors['mfaCode']}
              registration={register('mfaCode')}
            />
            <div>
              <Button
                style={{ display: "inline-block" }}
                variant="text"
                className="w-5/12"
                onClick={() => {
                  setMfaCheck(false);
                  setMfaUser("");
                  setUserPass(true);
                }}
              >
                Cancel
              </Button>
              <Button
                style={{ float: "right" }}
                isLoading={isLoggingIn}
                type="submit"
                className="w-5/12"
              >
                Confirm
              </Button>
            </div>
          </>
          }
          </>
        )}
      </Form>
      {/* <div className="mt-2 flex items-center justify-end">
        <div className="text-sm">
          <Link to="../register" className="font-medium text-blue-600 hover:text-blue-500">
            Register
          </Link>
        </div>
      </div> */}
    </div>
  );
};
