/* eslint-disable import/no-unresolved */
import logo from '@/assets/CDE_OnlyLogo.png';
import { useAuth } from '@/lib/auth';
import { useAuthorization, ROLES } from '@/lib/authorization';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import {
  BoltIcon,
  ChartBarIcon,
  FolderIcon,
  FolderOpenIcon,
  GlobeAltIcon,
  GlobeAmericasIcon,
  MagnifyingGlassCircleIcon,
  MapIcon,
  UserIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import clsx from 'clsx';
import * as React from 'react';
import { useNavigate } from 'react-router';
import { NavLink, Link } from 'react-router-dom';

type NavigationItem = {
  name: string;
  to: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => React.ReactNode;
};

const baseClasses = 'group flex items-center px-3 py-2 text-sm font-medium';
const activeClasses = 'bg-gray-900 text-white';
const inactiveClasses = 'text-gray-300 hover:bg-gray-700 hover:text-white';

const DesktopNavigation = () => {
  const { checkAccess } = useAuthorization();
  const navigation = [
    //{ name: 'Situational Awareness', to: './sit-awareness', icon: MapIcon },
    { name: 'Discover', to: './discover', icon: GlobeAltIcon },
    { name: 'Packages', to: './data-packages', icon: FolderIcon },
    { name: 'About', to: './about', icon: FolderOpenIcon },

    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'APIs',
      to: './api-health',
      icon: ChartBarIcon,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Users',
      to: './users',
      icon: UsersIcon,
    },
  ].filter(Boolean) as NavigationItem[];

  return (
    <>
      {navigation.map((item, index) => {
        return (
          <NavLink
            end={index === 0}
            key={item.name}
            to={item.to}
            className={({ isActive }) =>
              clsx(baseClasses, isActive ? activeClasses : inactiveClasses)
            }
          >
            {item.name}
          </NavLink>
        );
      })}
    </>
  );
};

/**
 * Navigation rendered for mobile and smaller viewport devices.
 * @returns
 */
const HiddenNavigation = () => {
  const { checkAccess } = useAuthorization();

  const navigation = [
    //{ name: 'Situational Awareness', to: './sit-awareness', icon: MapIcon },
    { name: 'Discover', to: './discover', icon: GlobeAmericasIcon },
    { name: 'Packages', to: './data-packages', icon: FolderIcon },
    { name: 'About', to: './about', icon: FolderIcon },

    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'APIs',
      to: './api-health',
      icon: ChartBarIcon,
    },

    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Users',
      to: './users',
      icon: UsersIcon,
    },
  ].filter(Boolean) as NavigationItem[];

  return (
    <>
      {navigation.map((item, index) => (
        <Disclosure.Button
          as={NavLink}
          key={item.name}
          to={item.to}
          end={index === 0}
          className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-sm font-medium"
        >
          {item.name}
        </Disclosure.Button>
      ))}
    </>
  );
};

type UserNavigationItem = {
  name: string;
  to: string;
  onClick?: () => void;
};

const UserNavigation = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const userNavigation = [
    {
      name: `${user.passwordEnabled ? "Change" : "Create"} password`,
      to: '/users/changePassword',
    },
    {
      name: 'Sign out',
      to: '',
      onClick: () => {
        logout();
      },
    },
  ].filter(Boolean) as UserNavigationItem[];

  return (
    <Menu as="div" className="bg-gray 800 ml-3 relative">
      {({ open }) => (
        <>
          <div className="z-50">
            <Menu.Button className="max-w-xs bg-gray-200 p-2 flex items-center text-sm rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <span className="sr-only">Open user menu</span>
              <span className="ml-2">{user.email.split("@")[0]}</span>
              <UserIcon className="h-4 w-4 ml-2 rounded-full" />
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={React.Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="z-50 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              {userNavigation.map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <Link
                      onClick={item.onClick}
                      to={item.to}
                      className={clsx(
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700'
                      )}
                    >
                      {item.name}
                    </Link>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

const NavigationBar = () => {
  const { logout } = useAuth();
  return (
    <Disclosure as="nav" className="bg-gray-800">
      {({ open }) => (
        <>
          <div className="max-w-1xl mx-auto px-2 sm:px-4 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="flex items-center px-2 lg:px-0">
                <div className="flex-shrink-0">
                  <img src={logo} className="w-12 h-12" alt="CDE Logo" />
                </div>
                <div className="hidden lg:block lg:ml-6">
                  <div className="flex space-x-4">
                    <DesktopNavigation />
                  </div>
                </div>
              </div>
              <div className="flex-1 flex justify-center px-2 lg:ml-6 lg:justify-end">
                <div className="hidden max-w-lg w-full lg:max-w-xs">
                  <label htmlFor="search" className="sr-only">
                    Search
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <MagnifyingGlassCircleIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                    <input
                      id="search"
                      name="search"
                      className="block w-full pl-10 pr-3 py-2 border border-transparent rounded-md leading-5 bg-gray-700 text-gray-300 placeholder-gray-400 focus:outline-none focus:bg-white focus:border-white focus:ring-white focus:text-gray-900 sm:text-sm"
                      placeholder="Search"
                      type="search"
                    />
                  </div>
                </div>
              </div>
              <div className="flex lg:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <BoltIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>

              <div className="md:block md:ml-2">
                <div className="flex items-center z-50">
                  <UserNavigation />
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              <HiddenNavigation />
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

type MainLayoutProps = {
  children: React.ReactNode;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  return (
    <>
      <div className="h-screen min-h-screen flex overflow-hidden bg-gray-100">
        <div className="flex flex-col w-0 flex-1">
          {/* Navigation Bar */}
          <div className="">
            <div className="pt-0 flex flex-shrink-0 border-b">
              <div className="grow items-stretch max-w-full w-full">
                <NavigationBar />
              </div>
            </div>
          </div>

          {/* Main Content */}
          <div className="flex flex-1 overflow-hidden relative z-0">
            <main className="flex flex-1 flex-col z-0 focus:outline-none relative xl:order-last">
              {children}
            </main>
          </div>
        </div>
      </div>
    </>
  );
};
