import * as React from 'react';

import lgLogo from '@/assets/Logo_lg.png';
import { Link } from '@/components/Elements';
import { Head } from '@/components/Head';

type LayoutProps = {
  children: React.ReactNode;
  title: string;
  showLogo?: boolean;
};

export const Layout = ({ children, title, showLogo = true }: LayoutProps) => {
  return (
    <>
      <Head title={title} />
      <div className="min-h-screen bg-gray-50 flex flex-col justify-top py-12 sm:px-6 lg:px-8">
        {showLogo && (
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <div className="flex justify-center">
              <Link className="flex items-center text-white" to="/">
                <img className="h-auto w-auto" src={lgLogo} alt="CDE Logo" />
              </Link>
            </div>
          </div>
        )}

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">{children}</div>
        </div>
      </div>
    </>
  );
};
