import { useRoutes, Navigate } from 'react-router-dom';

import { useAuth } from '@/lib/auth';

import { protectedRoutes } from './protected';
import { publicRoutes } from './public';

// eslint-disable-next-line
import { Login } from '@/features/auth/routes/Login';

export const AppRoutes = () => {
  const auth = useAuth();

  const commonRoutes = [
    { path: '*', element: <Navigate to="/" />},
    { path: '/', element: <Login /> },
  ];

  const routes = auth.user ? protectedRoutes : publicRoutes;

  const element = useRoutes([...routes, ...commonRoutes]);

  return <>{element}</>;
};
