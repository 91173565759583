import { CloudArrowDownIcon } from '@heroicons/react/24/outline';
import * as React from 'react';

import { Head } from '../Head';

type PackageContentLayoutProps = {
  children: React.ReactNode;
  enableControls?: boolean;
  customControls?: React.ReactNode;
  dateRange?: string;
  title: string;
  onUpdatePackage?: () => void;
  onRefetchAllDevices?: () => void;
  lastUpdatedDate?: Date;
  createdBy?: string;
};

/**
 * Permits content to stretch the full width of the page with no padding at top, left.
 * Includes additional functions and buttons pertaining to a DataPackage.
 * TODO: Refactor to make this more generic and allow for an "actions" section.
 * @param param0
 * @returns
 */
export const PackageContentLayout = ({
  children,
  enableControls = true,
  customControls,
  title,
  dateRange,
  onUpdatePackage,
  onRefetchAllDevices,
  lastUpdatedDate,
  createdBy,
}: PackageContentLayoutProps) => {
  /**
   * Makes callback to let parent component know to update the underlying package.
   */
  const handleOnUpdatePackage = () => {
    if (!onUpdatePackage) return;
    onUpdatePackage();
  };

  /**
   * Makes callback to let parent component know to update the underlying package.
   */
  const handleOnRefetchAllDevices = () => {
    if (!onRefetchAllDevices) return;
    onRefetchAllDevices();
  };

  return (
    <>
      <Head title={title} />
      <div className="py-2 mt-1 pl-4 pb-2 sm:flex sm:items-center sm:justify-between">
        <h1 className="text-xl leading-6 font-semibold text-gray-900">
          {title} <small className="text-xs inline-block">{dateRange}</small>
        </h1>
        <div className="pr-3 mt-3 flex sm:mt-0 sm:ml-4">
          {enableControls && (
            <>
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={handleOnRefetchAllDevices}
              >
                <CloudArrowDownIcon
                  className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span>Pull Updates</span>
              </button>
            </>
          )}
          {customControls && <>{customControls}</>}
        </div>
      </div>
      <div className="flex-1 mx-auto h-full w-full">{children}</div>
    </>
  );
};
