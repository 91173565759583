/* eslint-disable import/no-unresolved */
import { v4 as uuidv4 } from 'uuid';

import { Spinner } from '@/components/Elements';
import ScrollableTable from '@/components/Elements/ScrollableTable/ScrollableTable';

import { useApiHealth } from '../api';

export const ApiHealthList = () => {
  const apiHealthQuery = useApiHealth();

  if (apiHealthQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!apiHealthQuery.data) return null;

  return (
    <ScrollableTable
      data={apiHealthQuery.data}
      columns={[
        {
          Header: 'API Name',
          accessor: 'name',
          // @ts-ignore
          Cell: ({ value }: string) => {
            return <span className="text-md font-semibold">{value}</span>;
          },
        },
        {
          Header: 'Response Code/Text',
          id: uuidv4(),
          accessor: (row: any) => row,
          // @ts-ignore
          Cell: ({ value }: any) => {
            return <><span className={value.responseCode !== 200 ? "text-red-600" : "text-green-600"}>{value.responseCode}</span> / <span className={value.responseText.indexOf('limit') > -1 ? "text-red-600" : "text-green-600"}>{value.responseText}</span></>;
          },
        },
        {
          Header: 'Response Time',
          accessor: 'responseTimeMs',
          // @ts-ignore
          Cell: ( { value }: number) => {
            return <span>{(value / 1000).toFixed(2)} seconds</span>;
          },
        },
        {
          Header: 'Day/Month/YTD Count',
          id: uuidv4(),
          accessor: (row: any) => row,
          // @ts-ignore
          Cell: ({ value }: any) => {
            return <span>{value.queryDayCount || 0} / {value.queryMonthCount || 0} / {value.queryYTD || 0}</span>
          },
        },
        {
          Header: 'Daily Avg. YTD/All',
          id: uuidv4(),
          accessor: (row: any) => row,
          // @ts-ignore
          Cell: ({ value }: any) => {
            return <span>{(value.queryDailyAverageYTD || 0).toFixed(2)} / {(value.queryDailyAverageAllTime || 0).toFixed(2)}</span>
          },
        },
        {
          Header: 'Monthly Avg. YTD/All',
          id: uuidv4(),
          accessor: (row: any) => row,
          // @ts-ignore
          Cell: ({ value }: any) => {
            return <span>{(value.queryMonthlyAverageYTD || 0).toFixed(2)} / {(value.queryMonthlyAverageAllTime || 0).toFixed(2)}</span>
          },
        },
        {
          Header: 'Check Timestamp',
          accessor: 'timestamp',
          // @ts-ignore
          Cell: ({ value }: any) => {
            return <span>{value}</span>;
          },
        },
      ]}
    />
  );
};
