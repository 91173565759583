import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import { Button, Spinner } from '@/components/Elements';
import { Notifications } from '@/components/Notifications/Notifications';
import { PUBLIC_URL } from '@/config';
import { AuthProvider } from '@/lib/auth';
import { queryClient } from '@/lib/react-query';

const ErrorFallback = () => {
  return (
    <div
      className="text-red-500 w-screen h-screen flex flex-col justify-center items-center"
      role="alert"
    >
      <h2 className="text-lg font-semibold">
        CDE ran into an problem. The issue has been logged. Email{' '}
        <a href="mailto:cde-support@marconiedison.com">Support</a> for additional help.
      </h2>
      <Button className="mt-4" onClick={() => window.location.assign("/discover")}>
        Refresh
      </Button>
    </div>
  );
};

type AppProviderProps = {
  children: React.ReactNode;
};

/**
 * Logs error message to console.
 * @param error error message
 */
const logError = (error: any) => {
  console.error(error);
};

/**
 * Wraps query, notifications, and authorization providers so that we only do this once.
 * @param param0
 * @returns
 */
export const AppProvider = ({ children }: AppProviderProps) => {
  const queryClientProvider = (
    <QueryClientProvider client={queryClient}>
      <Notifications />
      <AuthProvider>
        {/* @ts-ignore */}
        <BrowserRouter basename={PUBLIC_URL}>{children}</BrowserRouter>
      </AuthProvider>
    </QueryClientProvider>
  );

  // @ts-ignore // reference: https://github.com/nfl/react-helmet/issues/646
  const helmetProvider = <HelmetProvider>{queryClientProvider}</HelmetProvider>;

  const errorBoundary = (
    // @ts-ignore // reference: https://github.com/nfl/react-helmet/issues/646
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
      {helmetProvider}
    </ErrorBoundary>
  );

  const fallbackBoundary = (
    <div className="flex items-center justify-center w-screen h-screen">
      <Spinner size="xl" />
    </div>
  );

  return <React.Suspense fallback={fallbackBoundary}>{errorBoundary}</React.Suspense>;
};
