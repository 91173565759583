import { Helmet, HelmetData } from 'react-helmet-async';

type HeadProps = {
  title?: string;
  description?: string;
};

/**
 * Swaps in a new browser header title or uses the default.
 * @param param0
 * @returns
 */
export const Head = ({ title = '', description = '' }: HeadProps = {}) => {
  const helmet = (
    // @ts-ignore // reference: https://github.com/nfl/react-helmet/issues/646
    <Helmet title={title ? `${title} | CDE` : undefined} defaultTitle="CDE">
      <meta name="description" content={description} />
    </Helmet>
  );

  return <>{helmet}</>;
};
