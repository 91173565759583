import { initReactQueryAuth } from 'react-query-auth';

import { Spinner } from '@/components/Elements';
import {
  loginWithEmailAndPassword,
  loginWithCac,
  loginWithMfa,
  getUser,
  registerWithEmailAndPassword,
  UserResponse,
  LoginCredentialsDTO,
  MfaCredentialsDTO,
  RegisterCredentialsDTO,
  AuthUser,
} from '@/features/auth';
import storage from '@/utils/storage';

async function handleUserResponse(data: UserResponse) {
  const { jwt, user } = data;
  if (jwt) {
    storage.setToken(jwt);
  }
  return user;
}

async function loadUser() {
  const token = storage.getToken();
  if (token) {
    return getUser()
      .then(data => {
        // temporary fix for expired JWT
        if (data['message']) {
          const message = data['message'];
          if (message.includes('expired')) {
            console.warn(`User's JWT has expired. Logging out and clearing token.`);
            // trigger logoutFn
            logoutFn();
            return null;
          }
        }
        return data;
      })
      .catch(err => {
        // for now assuming error on loading user means the token is invalid or expired
        logoutFn();
        return null;
      });
  } else {
    return null
  }
}

async function loginFn(data: LoginCredentialsDTO) {
  let response = null;
  if (data.email && data.password && data.email.length > 0 && data.password.length > 0) {
    response = await loginWithEmailAndPassword(data);
    let u: AuthUser = {
      email: response?.email ? response.email : "",
      createdDate: response?.createdDate ? response.createdDate : null,
      isActive: response?.isActive ? response.isActive : false,
      isDefault: response?.isDefault ? response.isDefault : false,
      roles: response?.roles ? response.roles : [],
      cacEnabled: response?.cacEnabled ? response.cacEnabled : false,
      passwordEnabled: response?.passwordEnabled ? response.passwordEnabled : false,
      role: response?.roles && response.roles.indexOf("ADMIN") > -1 ? "ADMIN" : "USER",
    };
    storage.setMfaUser(u.email);
    return u;
  } else if (data.values) {
    response = await loginWithCac(data.values);
  } else if (data.mfaCode && data.mfaCode.length > 0) {
    response = await loginWithMfa({ email: data.email, mfaCode: data.mfaCode });
  }
  const user = await handleUserResponse(response);
  return user;
}

async function registerFn(data: RegisterCredentialsDTO) {
  const response = await registerWithEmailAndPassword(data);
  const user = await handleUserResponse(response);
  return user;
}

async function logoutFn() {
  storage.clearLoginAcknowledge();
  storage.clearMfaUser();
  storage.clearToken();
  window.location.assign((window.location.origin + '/') as unknown as string);
}

const authConfig = {
  loadUser,
  loginFn,
  registerFn,
  logoutFn,
  LoaderComponent() {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <Spinner size="xl" />
      </div>
    );
  },
};

export const { AuthProvider, useAuth } =
  initReactQueryAuth<AuthUser | null, unknown, LoginCredentialsDTO, RegisterCredentialsDTO>(
    authConfig
  );
