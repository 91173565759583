/* eslint-disable import/no-unresolved */
import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { useQuery } from 'react-query';

export const getApiHealth = (): Promise<any[]> => {
  return axios.get(`/api/v1/api-health/rollup`);
};

type UseOptions = {
  config?: QueryConfig<typeof getApiHealth>;
};

export const useApiHealth = ({ config }: UseOptions = {}) => {
  return useQuery({
    ...config,
    queryKey: ['apiHealth'],
    queryFn: () => getApiHealth(),
  });
};
