import { v4 as uuidv4 } from "uuid";

const storagePrefix = 'cde_';

const storage = {
  getToken: () => {
    return JSON.parse(window.sessionStorage.getItem(`${storagePrefix}token`) as string);
  },
  setToken: (token: string) => {
    window.sessionStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  clearToken: () => {
    window.sessionStorage.removeItem(`${storagePrefix}token`);
  },
  getMfaUser: () => {
    return JSON.parse(window.sessionStorage.getItem(`${storagePrefix}mfa`) as string);
  },
  setMfaUser: (mfa: string) => {
    window.sessionStorage.setItem(`${storagePrefix}mfa`, JSON.stringify(mfa));
  },
  clearMfaUser: () => {
    window.sessionStorage.removeItem(`${storagePrefix}mfa`);
  },
  getLoginAcknowledge: () => {
    return JSON.parse(window.sessionStorage.getItem(`${storagePrefix}notice`) as string);
  },
  setLoginAcknowledge: () => {
    window.sessionStorage.setItem(`${storagePrefix}notice`, JSON.stringify(uuidv4().replaceAll('-', '')));
  },
  clearLoginAcknowledge: () => {
    window.sessionStorage.removeItem(`${storagePrefix}notice`);
  },
};

export default storage;
