import { AppProvider } from '@/providers/app';
import { RecoilStateProvider } from '@/providers/recoil-state';
import { AppRoutes } from '@/routes';

function App() {
  console.log('--- Application [CDE] is loading. ---');

  return (
    <AppProvider>
      <RecoilStateProvider>
        <AppRoutes />
      </RecoilStateProvider>
    </AppProvider>
  );
}

export default App;
