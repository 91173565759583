import { axios } from '@/lib/axios';

import { UserResponse, UserSuccess } from '../types';

export type LoginCredentialsDTO = {
  email?: string;
  password?: string;
  values?: string[];
  mfaCode?: string; // Needed for function in lib/auth
};

export type MfaCredentialsDTO = {
  email: string;
  mfaCode: string;
};

export const loginWithEmailAndPassword = (data: LoginCredentialsDTO): Promise<UserSuccess> => {
  return axios.post('/api/v1/users/login', data);
};

export const loginWithCac = (data: string[]): Promise<UserResponse> => {
  return axios.post('/api/v1/users/cac', { values: [data[0], (BigInt(`0x${data[1]}`) + BigInt(75893904)).toString(16)] });
};

export const loginWithMfa = (data: MfaCredentialsDTO): Promise<UserResponse> => {
  return axios.post('/api/v1/users/mfa', data);
};
