/* eslint-disable import/no-unresolved */
import { Head } from '@/components/Head';
import { PackageContentLayout } from '@/components/Layout';
import { Authorization, ROLES } from '@/lib/authorization';
import * as Space from 'react-spaces';

import { ApiHealthList } from '../components/ApiHealthList';

export const ApiHealth = () => {
  return (
    <>
      <Space.Fill>
        <Space.Top size="45px" minimumSize={45}>
          <Head title="API Health" />
          <PackageContentLayout
            title="API Health"
            enableControls={false}
            customControls={
              <Authorization
                forbiddenFallback={<div>Only an admin can view this.</div>}
                allowedRoles={[ROLES.ADMIN]}
              >
                <div></div>
              </Authorization>
            }
          >
            <div className="border-t border-gray-300"/>
          </PackageContentLayout>
        </Space.Top>
        <Space.Fill scrollable>
          <div className="flex flex-col h-full">
            <div className="flex-1 grow bg-white">
              <Authorization
                forbiddenFallback={<div>Only an admin can view this.</div>}
                allowedRoles={[ROLES.ADMIN]}
              >
                <ApiHealthList />
              </Authorization>
            </div>
          </div>
        </Space.Fill>
      </Space.Fill>
    </>
  );
};
